import axios from "axios";
import queryString from "query-string";
import Dto from "../../domain/dtos/Dto";
import logger from "../logService";

function RestService(
  baseUrl: string,
  path: string,
  apiToken: string | null = null
) {
  const _validateUrlTrailingSlash = (str: string) => {
    if (typeof str !== "string" || str === "") return "";
    return str.slice(-1) === "/" ? str : str + "/";
  };

 const _validateApiToken = (token: string | null): string => {
  if (!token || token.trim() === "") {
    return "";
  }
  return token.startsWith("Bearer ") ? token : `Bearer ${token}`;
};

  baseUrl = _validateUrlTrailingSlash(baseUrl);
  path = _validateUrlTrailingSlash(path);
  apiToken = _validateApiToken(apiToken);

  const http = axios.create({
    baseURL: baseUrl,
    withCredentials: false,
    headers: {
      Accept: "application/json",
      Authorization: apiToken,
      "Content-Type": "application/json; charset=utf-8",
    },
  });

  http.interceptors.response.use(undefined, (error) => {
    const expectedError =
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500;

    if (!expectedError) {
      logger.error(error);
    }

    return Promise.reject(error);
  });

  const getAll = (filter: any) =>
    http.get(`${path}?${queryString.stringify(filter)}`);

  const getById = (id: string) => http.get(path + id);

  const create = (item: any) => http.post(path, item);

  const update = (item: Dto) => http.put(path + item.id, item);

  const remove = (id: string) => http.delete(path + id);

  return {
    get: http.get,
    put: http.put,
    post: http.post,

    getAll,
    getById,
    create,
    update,
    delete: remove,
  };
}

export default RestService;
